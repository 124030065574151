import { Dispatch } from 'redux';
import useRequest from '../../../../../hooks/useRequest';
import { ActionType } from '../action-types';
import { Action } from '../actions';
import { actionCreators } from '../../../../../state';
import i18n from 'i18next';

export const pp_homepage_get_objects = (id = '') => {
    return async (dispatch: Dispatch<Action> | ((error: any) => void)) => {
        const host = window.location.host;

        dispatch({
            type: ActionType.PP_HOMEPAGE_GET_DATA_PENDING,
        });

        const { doRequest } = useRequest({
            url: `https://sheets.googleapis.com/v4/spreadsheets/1YBBp28bgUDPPBasLoUPl1YpkyUc4jw5iI26CzAgEsKI/values/Main?alt=json&key=AIzaSyDrvjDsyAEskrZ85uL0PGiCCY4zTUxnlUs`,
            method: 'get',
            body: {},
            onSuccess: (data) => {
                dispatch({
                    type: ActionType.PP_HOMEPAGE_GET_DATA_SUCCESS,
                    payload: data,
                });
                dispatch(actionCreators.benit_central_ui_delete_all_errors());
            },
            onError: (error) => dispatch(actionCreators.benit_central_ui_add_errors(error)),
        });

        doRequest();
    };
};
