import { ActionType } from '../action-types';
import { Action } from '../actions';
import i18n from 'i18next';

interface BenitLandingPageState {
    data: any | null;
    dataPending: boolean;
}

const initialState = {
    data: null,
    dataPending: true,
};

const reducer = (state: BenitLandingPageState = initialState, action: Action): BenitLandingPageState => {
    switch (action.type) {
        case ActionType.PP_HOMEPAGE_GET_DATA_PENDING:
            return {
                ...state,
            };
        case ActionType.PP_HOMEPAGE_GET_DATA_SUCCESS:
            return {
                ...state,
                data: action.payload,
                dataPending: false,
            };
        default:
            return state;
    }
};

export default reducer;
