// @ts-nocheck
import { Route, withRouter, useHistory, useLocation } from 'react-router-dom';
import { useTypedSelector } from '../../../../../hooks/useTypedSelector';
import { useActions } from '../../../../../hooks/useActions';
import { useEffect, useState } from 'react';
import LandingPage from '../../../pages/components/LandingPage';
import Footer from './MainWindow/Footer/Footer';
import Navbar from './MainWindow/Navbar/Navbar';
import { LoadingAnimation } from './MainWindow/LoadingAnimation/LoadingAnimation';

const MainWrapper = () => {
    const location = useLocation();
    const [portfolioPictures, setPortfolioPictures] = useState<any>(null);
    const [references, setReferences] = useState<any>(null);

    const { pp_homepage_get_objects } = useActions();
    const { data, dataPending } = useTypedSelector((state) => state.riReducer);

    // useEffect(() => {
    //     window.scrollTo(0, 0);
    // }, [location]);

    useEffect(() => {
        setTimeout(function () {
            $('.no-slider .left').addClass('init');
            $('.no-slider .right').addClass('init');
        }, 1200);

        var animation = function (slider) {
            let image = $(slider + ' .swiper-slide-active img');
            let title = $(slider + ' .title');
            let description = $(slider + ' .description');
            let btn = $(slider + ' .btn');
            let nav = $(slider + ' nav');

            image.toggleClass('aos-animate');
            title.toggleClass('aos-animate');
            description.toggleClass('aos-animate');
            btn.toggleClass('aos-animate');
            nav.toggleClass('aos-animate');

            setTimeout(function () {
                image.toggleClass('aos-animate');
                title.toggleClass('aos-animate');
                description.toggleClass('aos-animate');
                btn.toggleClass('aos-animate');
                nav.toggleClass('aos-animate');

                AOS.refresh();
            }, 100);

            if ($('.full-slider').hasClass('animation')) {
                $('.full-slider .left').addClass('off');
                $('.full-slider .left').removeClass('init');
                $('.full-slider .right').addClass('off');
                $('.full-slider .right').removeClass('init');

                setTimeout(function () {
                    $('.full-slider .left').removeClass('off');
                    $('.full-slider .right').removeClass('off');
                }, 200);

                setTimeout(function () {
                    $('.full-slider .left').addClass('init');
                    $('.full-slider .right').addClass('init');
                }, 1000);
            } else {
                $('.full-slider .left').addClass('init');
                $('.full-slider .right').addClass('init');
            }
        };

        var fullSlider = new Swiper('.full-slider', {
            autoplay: {
                delay: 10000,
            },
            loop: false,
            slidesPerView: 1,
            spaceBetween: 0,
            navigation: false,
            pagination: {
                el: '.full-slider .swiper-pagination',
                clickable: true,
            },
            keyboard: {
                enabled: true,
                onlyInViewport: false,
            },
            on: {
                init: function () {
                    animation('.full-slider');
                    let pagination = $('.full-slider .swiper-pagination');
                    pagination.hide();

                    setTimeout(function () {
                        pagination.show();
                    }, 2000);
                },
                slideChange: function () {
                    animation('.full-slider');
                },
                sliderMove: function () {
                    let slider = $('.full-slider');
                    if (slider.hasClass('animation')) {
                        $('.full-slider .swiper-slide-next .left').addClass('off');
                        $('.full-slider .swiper-slide-next .right').addClass('off');
                        $('.full-slider .swiper-slide-prev .left').addClass('off');
                        $('.full-slider .swiper-slide-prev .right').addClass('off');
                    }
                },
            },
        });

        $('.mid-slider').each(function () {
            if ($(this).data('perview')) {
                var midPerView = $(this).data('perview');
            } else {
                midPerView = 3;
            }

            if ($(this).data('autoplay') && $(this).data('autoplay') == true) {
                var midAutoPlay = { delay: 5000 };
            } else {
                midAutoPlay = false;
            }

            var midSlider = new Swiper(this, {
                autoplay: midAutoPlay,
                loop: true,
                slidesPerView: 1,
                spaceBetween: 30,
                breakpoints: {
                    767: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    1023: {
                        slidesPerView: midPerView,
                        spaceBetween: 30,
                    },
                },
                pagination: {
                    el: '.mid-slider .swiper-pagination',
                    clickable: true,
                },
            });
        });

        $('.mid-slider-simple').each(function () {
            if ($(this).data('perview')) {
                var midSimplePerView = $(this).data('perview');
            } else {
                midSimplePerView = 3;
            }

            if ($(this).data('autoplay') && $(this).data('autoplay') == true) {
                var midSimpleAutoPlay = { delay: 5000 };
            } else {
                midSimpleAutoPlay = false;
            }

            var midSliderSimple = new Swiper(this, {
                autoplay: midSimpleAutoPlay,
                loop: false,
                centerInsufficientSlides: true,
                slidesPerView: 1,
                spaceBetween: 30,
                breakpoints: {
                    767: {
                        slidesPerView: 2,
                        spaceBetween: 30,
                    },
                    1023: {
                        slidesPerView: midSimplePerView,
                        spaceBetween: 30,
                    },
                },
                pagination: {
                    el: '.mid-slider-simple .swiper-pagination',
                    clickable: true,
                },
            });
        });

        var minSlider = new Swiper('.min-slider', {
            autoplay: {
                delay: 5000,
            },
            loop: false,
            centerInsufficientSlides: true,
            slidesPerView: 2,
            spaceBetween: 15,
            breakpoints: {
                424: {
                    slidesPerView: 2,
                    spaceBetween: 15,
                },
                767: {
                    slidesPerView: 3,
                    spaceBetween: 15,
                },
                1023: {
                    slidesPerView: 4,
                    spaceBetween: 15,
                },
                1199: {
                    slidesPerView: 5,
                    spaceBetween: 15,
                },
            },
            pagination: false,
        });

        var noSlider = new Swiper('.no-slider', {
            autoplay: false,
            loop: false,
            keyboard: false,
            grabCursor: false,
            allowTouchMove: false,
            on: {
                init: function () {
                    animation('.no-slider');
                },
            },
        });

        $('.filter-section').each(function (index) {
            var count = index + 1;

            $(this)
                .find('.filter-items')
                .removeClass('filter-items')
                .addClass('filter-items-' + count);
            $(this)
                .find('.filter-item')
                .removeClass('filter-item')
                .addClass('filter-item-' + count);
            $(this)
                .find('.filter-sizer')
                .removeClass('filter-sizer')
                .addClass('filter-sizer-' + count);
            $(this)
                .find('.btn-filter-item')
                .removeClass('btn-filter-item')
                .addClass('btn-filter-item-' + count);

            var Shuffle = window.Shuffle;
            var Filter = new Shuffle(document.querySelector('.filter-items-' + count), {
                itemSelector: '.filter-item-' + count,
                sizer: '.filter-sizer-' + count,
                buffer: 1,
            });

            $('.btn-filter-item-' + count).on('change', function (e) {
                var input = e.currentTarget;

                if (input.checked) {
                    Filter.filter(input.value);
                }
            });
        });
    });

    useEffect(() => {
        pp_homepage_get_objects();
    }, []);

    useEffect(() => {
        if (data) {
            let tmpPictures = [];
            let tmpReferences = [];
            for (let i = 11; i < data.values.length; i++) {
                if (data.values[i][0].length > 0) {
                    tmpPictures.push(data.values[i]);
                }
                if (data.values[i].length >= 6 && data.values[i][5].length > 0) {
                    tmpReferences.push(data.values[i]);
                }
            }
            setPortfolioPictures(tmpPictures);
            setReferences(tmpReferences);
        }
    }, [data]);

    return (
        <>
            {dataPending ? <LoadingAnimation /> : null}
            <div>
                <header id="header">
                    {/* Navbar */}
                    <nav data-aos="zoom-out" data-aos-delay={800} className="navbar navbar-expand">
                        <div className="container header">
                            {/* Navbar Brand*/}
                            <a className="navbar-brand" href="/">
                                Polina Piatykop.
                                {/* <i className="version-number">Fotografin</i> */}
                                {/* 
          Custom Logo
          <img src="assets/images/logo.svg" alt="Leverage">
      */}
                            </a>
                            {/* Nav holder */}
                            <div className="ml-auto" />
                            {/* Navbar Items */}
                            <ul className="navbar-nav items">
                                <li className="nav-item">
                                    <a href="#header" className="nav-link smooth-anchor">
                                        Home
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#content" className="nav-link smooth-anchor">
                                        Über Mich
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#portfolio-grid" className="nav-link smooth-anchor">
                                        Portfolio
                                    </a>
                                </li>
                                <li className="nav-item">
                                    <a href="#testimonials" className="nav-link smooth-anchor">
                                        Referenzen
                                    </a>
                                </li>
                            </ul>
                            {/* Navbar Icons */}
                            <ul className="navbar-nav icons">
                                <li className="nav-item social">
                                    <a href={`${data ? data.values[7][3] : ''}`} target="_blank" className="nav-link">
                                        <i className="icon-social-instagram" />
                                    </a>
                                </li>
                            </ul>
                            {/* Navbar Toggle */}
                            <ul className="navbar-nav toggle">
                                <li className="nav-item">
                                    <a href="#" className="nav-link" data-toggle="modal" data-target="#menu">
                                        <i className="icon-menu m-0" />
                                    </a>
                                </li>
                            </ul>
                            {/* Navbar Action */}
                            <ul className="navbar-nav action">
                                <li className="nav-item ml-3">
                                    <a href={`mailto: ${data ? data.values[1][3] : ''}`} target="_blank" className="btn ml-lg-auto primary-button smooth-anchor">
                                        <i className="icon-envelope" />
                                        KONTAKTIEREN SIE MICH
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </header>
                <div>
                    {/* Hero */}
                    <section id="slider" className="hero p-0">
                        <div className="swiper-container no-slider featured animation slider-h-100">
                            <div className="swiper-wrapper">
                                {/* Item 1 */}
                                <div className="swiper-slide slide-center">
                                    {/* <div id="particles-1" class="particles full-image" data-particle="bubble" data-mask="70"></div> */}
                                    <img data-aos="zoom-out-up" data-aos-delay={800} src={`${data ? data.values[4][1] : 'assets/images/hero-13.jpg'}`} className="hero-image-left" alt="Hero Image" />
                                    <div className="slide-content row" data-mask-768={50}>
                                        <div className="col-12 d-flex justify-content-end inner">
                                            <div className="right text-center text-md-left">
                                                <h1 data-aos="zoom-out-up" data-aos-delay={400} className="title effect-static-text">
                                                    {data ? data.values[1][1] : ''}
                                                    <br />
                                                    {data ? data.values[2][1] : ''}
                                                </h1>
                                                <p data-aos="zoom-out-up" data-aos-delay={800} className="description mx-auto">
                                                    {data ? data.values[3][1] : ''}
                                                </p>
                                                <div className="d-sm-inline-flex mt-3">
                                                    <a href="#content" data-aos="zoom-out-up" data-aos-delay={1200} className="smooth-anchor ml-auto mr-auto mr-md-0 mt-4 mt-sm-0 btn primary-button">
                                                        <i className="icon-arrow-down" />
                                                        ÜBER MICH
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    {/* Content */}
                    <section id="content" className="section-1 body-mode-dark single featured">
                        <div className="container">
                            <div className="row content">
                                {/* Main */}
                                <main className="col-12 col-lg-12 p-0">
                                    {/* Text */}
                                    <div className="row">
                                        <div className="col-12 align-self-center">
                                            <h2 className="featured mt-0 ml-0">Über Mich</h2>
                                            <p style={{ whiteSpace: 'pre-line', verticalAlign: 'bottom' }}>{data ? `${data.values[5][1]}` : ''}</p>
                                            <div className="gallery">
                                                <a href={`${data ? data.values[6][1] : 'assets/images/bg-1.jpg'}`}>
                                                    <img
                                                        style={{ width: 500, height: 700, objectFit: 'cover', objectPosition: '50% 50%' }}
                                                        src={`${data ? data.values[6][1] : 'assets/images/bg-1.jpg'}`}
                                                        className="w-100"
                                                    />
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </main>
                            </div>
                        </div>
                    </section>
                </div>

                <section id="portfolio-grid" className="section-2 odd showcase portfolio blog-grid filter-section featured">
                    <div className="overflow-holder">
                        <div className="container full-grid">
                            <div className="row text-center intro">
                                <div className="col-12">
                                    <h2 className="mb-0">Mein Portfolio</h2>
                                </div>
                            </div>
                            <div className="row justify-content-center text-center">
                                <div className="col-12">
                                    <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                        <label className="btn active">
                                            <input type="radio" defaultValue="all" defaultChecked className="btn-filter-item" />
                                            <span>All</span>
                                        </label>
                                        <label className="btn">
                                            <input type="radio" defaultValue="family" className="btn-filter-item" />
                                            <span>Familie</span>
                                        </label>
                                        <label className="btn">
                                            <input type="radio" defaultValue="kids" className="btn-filter-item" />
                                            <span>Kinder</span>
                                        </label>
                                        <label className="btn">
                                            <input type="radio" defaultValue="wedding" className="btn-filter-item" />
                                            <span>Hochzeit</span>
                                        </label>
                                        <label className="btn">
                                            <input type="radio" defaultValue="model" className="btn-filter-item" />
                                            <span>Model</span>
                                        </label>
                                        <label className="btn">
                                            <input type="radio" defaultValue="celebration" className="btn-filter-item" />
                                            <span>Feste</span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div className="row items filter-items">
                                {portfolioPictures
                                    ? portfolioPictures.map((picture: any) => {
                                          return (
                                              <div className="col-12 col-md-6 col-lg-3 item filter-item" data-groups={`${picture[1]}`}>
                                                  <div className="row card p-0 text-center">
                                                      <div className="gallery">
                                                          <div className="image-over">
                                                              <img src={`${picture[0]}`} style={{ width: 650, height: 500, objectFit: 'cover', objectPosition: '50% 50%' }} alt="Lorem ipsum" />
                                                          </div>
                                                      </div>
                                                      <div className="card-caption col-12 p-0">
                                                          <div className="card-body">
                                                              <h4 className="m-0">{`${picture[2]}`}</h4>
                                                          </div>
                                                          <div className="card-footer d-lg-flex align-items-center justify-content-center">
                                                              <p>{`${picture[3]}`} </p>
                                                          </div>
                                                      </div>
                                                  </div>
                                              </div>
                                          );
                                      })
                                    : null}

                                <div className="col-1 filter-sizer" />
                            </div>
                        </div>
                    </div>
                </section>

                <div>
                    <section id="testimonials" className="section-3 odd carousel custom featured">
                        <div className="overflow-holder">
                            <div className="container">
                                <div className="row text-center intro">
                                    <div className="col-12">
                                        <h2>Referenzen</h2>
                                        <p className="text-max-800" style={{ whiteSpace: 'pre-line', verticalAlign: 'bottom' }}>
                                            {data ? data.values[7][1] : ''}
                                        </p>
                                    </div>
                                </div>
                                <div className="swiper-container mid-slider-simple items">
                                    <div className="swiper-wrapper">
                                        {references
                                            ? references.map((reference: any) => {
                                                  return (
                                                      <div className="swiper-slide slide-center text-center item">
                                                          <div className="row card">
                                                              <div className="col-12">
                                                                  <img src={`${reference[5]}`} alt={reference[6]} className="person" />
                                                                  <h4>{reference[6]}</h4>
                                                                  <p style={{ whiteSpace: 'pre-line', verticalAlign: 'bottom' }}>{reference[7]}</p>
                                                                  <ul className="navbar-nav social share-list ml-auto">
                                                                      <li className="nav-item">
                                                                          <a href="#" className="nav-link">
                                                                              <i className="icon-star ml-2 mr-2" />
                                                                          </a>
                                                                      </li>
                                                                      <li className="nav-item">
                                                                          <a href="#" className="nav-link">
                                                                              <i className="icon-star ml-2 mr-2" />
                                                                          </a>
                                                                      </li>
                                                                      <li className="nav-item">
                                                                          <a href="#" className="nav-link">
                                                                              <i className="icon-star ml-2 mr-2" />
                                                                          </a>
                                                                      </li>
                                                                      <li className="nav-item">
                                                                          <a href="#" className="nav-link">
                                                                              <i className="icon-star ml-2 mr-2" />
                                                                          </a>
                                                                      </li>
                                                                      <li className="nav-item">
                                                                          <a href="#" className="nav-link">
                                                                              <i className="icon-star ml-2 mr-2" />
                                                                          </a>
                                                                      </li>
                                                                  </ul>
                                                              </div>
                                                          </div>
                                                      </div>
                                                  );
                                              })
                                            : null}
                                    </div>
                                    <div className="swiper-pagination" />
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Footer */}
                    <footer className="odd">
                        {/* Footer [links] */}
                        <section id="footer" className="footer">
                            <div className="container">
                                <div className="row items footer-widget">
                                    <div className="col-12 col-lg-8 p-3 text-center text-lg-left item">
                                        <h4 className="title">Kontakt</h4>
                                        <ul className="navbar-nav">
                                            <li className="nav-item">
                                                <a href={`tel: ${data ? data.values[0][3] : ''}`} className="nav-link">
                                                    <i className="icon-phone mr-2" />
                                                    {data ? data.values[0][3] : ''}
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a href={`mailto: ${data ? data.values[1][3] : ''}`} target="_blank" className="nav-link">
                                                    <i className="icon-envelope mr-2" />
                                                    {data ? data.values[1][3] : ''}
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a href={`https://www.google.com/maps/dir/${data ? data.values[2][3] : ''}`} target="_blank" className="nav-link">
                                                    <i className="icon-location-pin mr-2" />
                                                    {data ? data.values[2][3] : ''}
                                                </a>
                                            </li>
                                            <li className="nav-item">
                                                <a href={`mailto: ${data ? data.values[1][3] : ''}`} target="_blank" className="mt-4 mr-auto ml-auto ml-lg-0 btn primary-button smooth-anchor">
                                                    <i className="icon-envelope" />
                                                    KONTAKTIEREN SIE MICH
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-12 col-lg-4  p-0">
                                        <div className="row">
                                            <div className="branding col-12 p-3 text-center text-lg-right item">
                                                <div className="brand">
                                                    <a href="/" className="logo">
                                                        Polina Piatykop.
                                                        {/* 
                                          Custom Logo
                                          <img src="assets/images/logo.svg" alt="Leverage">
                                      */}
                                                    </a>
                                                </div>
                                                <p>
                                                    {data ? data.values[3][3] : ''}
                                                    <br />
                                                    {data ? data.values[4][3] : ''}
                                                    <br />
                                                    {data ? data.values[5][3] : ''}
                                                    <br />
                                                    {data ? data.values[6][3] : ''}
                                                </p>
                                                <ul className="navbar-nav social share-list mt-0 ml-auto">
                                                    <li className="nav-item">
                                                        <a href={`${data ? data.values[7][3] : ''}`} className="nav-link">
                                                            <i className="icon-social-instagram ml-0" />
                                                        </a>
                                                    </li>
                                                    {/* <li className="nav-item">
                                                    <a href="#" className="nav-link">
                                                        <i className="icon-social-facebook" />
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="#" className="nav-link">
                                                        <i className="icon-social-linkedin" />
                                                    </a>
                                                </li>
                                                <li className="nav-item">
                                                    <a href="#" className="nav-link">
                                                        <i className="icon-social-twitter" />
                                                    </a>
                                                </li> */}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </footer>
                    {/* #region Global ============================ */}
                    {/* Modal [search] */}
                    <div id="search" className="p-0 modal fade" role="dialog" aria-labelledby="search" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-slideout" role="document">
                            <div className="modal-content full">
                                <div className="modal-header" data-dismiss="modal">
                                    Search <i className="icon-close" />
                                </div>
                                <div className="modal-body">
                                    <form className="row">
                                        <div className="col-12 p-0 align-self-center">
                                            <div className="row">
                                                <div className="col-12 p-0 pb-3">
                                                    <h2>What are you looking for?</h2>
                                                    <p>Search for services and news about the best that happens in the world.</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 p-0 input-group">
                                                    <input type="text" className="form-control" placeholder="Enter Keywords" />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 p-0 input-group align-self-center">
                                                    <button className="btn primary-button">
                                                        <i className="icon-magnifier" />
                                                        SEARCH
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Modal [sign] */}
                    <div id="sign" className="p-0 modal fade" role="dialog" aria-labelledby="sign" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-slideout" role="document">
                            <div className="modal-content full">
                                <div className="modal-header" data-dismiss="modal">
                                    Sign In Form <i className="icon-close" />
                                </div>
                                <div className="modal-body">
                                    <form action="/" className="row">
                                        <div className="col-12 p-0 align-self-center">
                                            <div className="row">
                                                <div className="col-12 p-0 pb-3">
                                                    <h2>Sign In</h2>
                                                    <p>
                                                        Don't have an account?{' '}
                                                        <a href="#" className="primary-color" data-toggle="modal" data-target="#register">
                                                            Register now
                                                        </a>
                                                        .
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 p-0 input-group">
                                                    <input type="email" className="form-control" placeholder="Email" required />
                                                </div>
                                                <div className="col-12 p-0 input-group">
                                                    <input type="password" className="form-control" placeholder="Password" required />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 p-0 input-group align-self-center">
                                                    <button className="btn primary-button">
                                                        <i className="icon-login" />
                                                        LOGIN
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Modal [register] */}
                    <div id="register" className="p-0 modal fade" role="dialog" aria-labelledby="register" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-slideout" role="document">
                            <div className="modal-content full">
                                <div className="modal-header" data-dismiss="modal">
                                    Register Form <i className="icon-close" />
                                </div>
                                <div className="modal-body">
                                    <form action="/" className="row">
                                        <div className="col-12 p-0 align-self-center">
                                            <div className="row">
                                                <div className="col-12 p-0 pb-3">
                                                    <h2>Register</h2>
                                                    <p>
                                                        Have an account?{' '}
                                                        <a href="#" className="primary-color" data-toggle="modal" data-target="#sign">
                                                            Sign In
                                                        </a>
                                                        .
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 p-0 input-group">
                                                    <input type="text" className="form-control" placeholder="Name" required />
                                                </div>
                                                <div className="col-12 p-0 input-group">
                                                    <input type="email" className="form-control" placeholder="Email" required />
                                                </div>
                                                <div className="col-12 p-0 input-group">
                                                    <input type="password" className="form-control" placeholder="Password" required />
                                                </div>
                                                <div className="col-12 p-0 input-group">
                                                    <input type="password" className="form-control" placeholder="Confirm Password" required />
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12 p-0 input-group align-self-center">
                                                    <button className="btn primary-button">
                                                        <i className="icon-rocket" />
                                                        REGISTER
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Modal [responsive menu] */}
                    <div id="menu" className="p-0 modal fade" role="dialog" aria-labelledby="menu" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-slideout" role="document">
                            <div className="modal-content full">
                                <div className="modal-header" data-dismiss="modal">
                                    Menu <i className="icon-close" />
                                </div>
                                <div className="menu modal-body">
                                    <div className="row w-100">
                                        <div className="items p-0 col-12 text-center">{/* Append [navbar] */}</div>
                                        <div className="contacts p-0 col-12 text-center">{/* Append [navbar] */}</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* Scroll [to top] */}
                    <div id="scroll-to-top" className="scroll-to-top">
                        <a href="#header" className="smooth-anchor">
                            <i className="icon-arrow-up" />
                        </a>
                    </div>
                </div>
            </div>
        </>
    );
};

export default MainWrapper;
